import React, { useEffect, useState } from "react";
import Modal from "../../views/admin/Modal/Modal";
import validator from "validator";
import "./Connect.css";
import {
    errorNotification,
    successNotification,
} from "../../components/toast-notification/common-toast";
import {
    ADD_REPORT_CONNECT,
    GET_REPORT_CONNECT,
    GET_REPORT_CONNECT_EMPLOYEE,
    UPDATE_REPORT_CONNECT,
} from "../../components/utils";
import {
    getRequest,
    patchRequest,
    postRequest,
} from "../../components/axiosClient";
import LoadingSpinner from "../../../src/components/loader/LoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import CustomPagination from "../../components/common/CustomPagination";
import secureLocalStorage from "react-secure-storage";
import noDataFound from "../../components/assets/No data-rafiki 1.svg";


const Connect = () => {
    const [loading, setLoading] = useState(false);
    const [connectNumber, setConnectNumber] = useState(null);
    const [amount, setAmount] = useState(null);
    const [purchaseDate, setPurchaseDate] = useState("");
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [editedId, setEditedId] = useState(null);
    const [listshow, setlistShow] = useState(false);
    const [listallCompany, setListAllCompany] = useState([]);
    const [pageInformation, setPageInformation] = useState({});
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const startDateParams = searchParams.get('startdate');
    const [startDate, setStartDate] = useState(startDateParams ? startDateParams : "");

    const endDateParams = searchParams.get('enddate');
    const [endDate, setEndDate] = useState(endDateParams ? endDateParams : "");
    const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Connect")?.access_type !== "Read";
    const accountList = [
        { label: "upwork account 1", value: "upwork account 1" },
        { label: "upwork account 2", value: "upwork account 2" },
        { label: "upwork account 3", value: "upwork account 3" },
        { label: "upwork account 4", value: "upwork account 4" }
    ];
    // for new add connect API Call
    const addConnect = async (payload) => {
        try {
            const res = await postRequest(ADD_REPORT_CONNECT, payload, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    getAllCompany();
                    setAmount(null);
                    setPurchaseDate(null);
                    successNotification(res.response.data.message);
                    setConnectNumber("");
                    setSelectedAccount(null);
                    setlistShow(false);
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    };

const [memberList,setMemberList]=useState([]);

const memberParams = searchParams.get('purchase_by');
const [selectedMember,setSelectedMember]=useState(memberParams? memberParams : "");

    const getMembers = async () => {
        try {
            const url = `${GET_REPORT_CONNECT_EMPLOYEE}`;
            const res = await getRequest(url, null, navigate);
            if (res.data && Array.isArray(res.data.data)) {
                const options = res.data.data.map((project) => ({
                    label: `${project?.first_name} ${project?.last_name}`,
                    value: project?.id,
                }));
                setMemberList([{ label: "All", value: "" }, ...options]);
            } else {
                setMemberList([]);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    };


    // for Eidt connect data  API Call

    const editConnectData = async (payload) => {
        try {
            const res = await patchRequest(UPDATE_REPORT_CONNECT, payload, navigate);
            if (res.type === 1) {
                if (res.response.data.success) {
                    getAllCompany();
                    setAmount(null);
                    setPurchaseDate(null);
                    successNotification(res.response.data.message);
                    setConnectNumber("");
                    setSelectedAccount(null);
                    setlistShow(false);
                } else {
                    errorNotification(res.response.data.message);
                }
            }
            if (res.type === 2) {
                errorNotification(res.errormessage || res.errors);
            }
        } catch (error) {
            errorNotification(error.message);
        }
    };

    // for new add connect API Call
    const handleAddCompany = (e) => {
        e.preventDefault();


        if (!selectedAccount) {
            errorNotification("Please Select Account");
        } else if (validator.isEmpty(connectNumber.toString()) ||
            !validator.isFloat(connectNumber.toString(), { min: 0 })) {
            errorNotification("Please Enter valid Connect");
        } else if (validator.isEmpty(amount.toString()) ||
            !validator.isFloat(amount.toString(), { min: 0 })) {
            errorNotification("Please Enter valid Amount");
        } else if (purchaseDate && !validator.isDate(purchaseDate, new Date())) {
            errorNotification("please Enter valid purchase date");
        } else {

            let companyData = {
                connect: connectNumber,
                amount: amount,
                upwork_account: selectedAccount.value,
                purchase_date: purchaseDate,
            };

            if (editedId) {
                companyData.connect_record_id = editedId;
                editConnectData(companyData);
            } else {
                addConnect(companyData);
            }
        }
    };

    useEffect(() => {
        getAllCompany();
        getMembers();
    }, [page,startDate,endDate,selectedMember]);

    // for get all GET_REPORT_CONNECT list API Call
    const getAllCompany = async () => {
        try {
            const payload = { page: page, limit: pageSize };

            if(startDate){
                payload.start_date = startDate;
            }
            if(endDate){
                payload.end_date = endDate;
            }
            if(selectedMember){
                payload.purchase_by = selectedMember;
            }

            const apiUrl =`${GET_REPORT_CONNECT}`

            setLoading(true);
            const res = await getRequest(
                apiUrl,
                payload,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setListAllCompany(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
            } else {
                setListAllCompany([]);
            }
            setLoading(false);
        } catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    };

    //handle add btn click
    const handleAdd = () => {
        setConnectNumber("");
        setAmount(null);
        setPurchaseDate(null);
        setSelectedAccount(null);
        setlistShow(true);
    };

    // for edit company listing
    const handleEdit = (item) => {
        setConnectNumber(item.connect);
        setAmount(item.amount);
        setPurchaseDate(item.purchase_date);
        setSelectedAccount({ label: item.upwork_account, value: item.upwork_account });
        setEditedId(item.id);
        setlistShow(true);
    };


    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className="connect__section bg-[#fff] rounded-[12px] font-normal">
            <div className="connect__subsection">
                <div className="flex flex-col gap-4 lg:gap-0 lg:flex-row justify-between">
                    {role_accesses && (
                        <button
                            className="add__connect mx-3 mt-3 text-capitalize"
                            onClick={handleAdd}
                            disabled={!role_accesses}
                        >
                            + Add Connect
                        </button>
                    )}
                    <div className="flex flex-wrap lg:flex-row gap-3 items-center">

                        <Select
                            className="basic-single !w-[175px] text-capitalize "
                            classNamePrefix="select"
                            placeholder="member Filter"
                            name="member"
                            options={memberList}
                            value={memberList.find((option) => option.value === selectedMember)}
                            onChange={(selectedOption) => {
                                const currentParams = Object.fromEntries(searchParams.entries());
                                const updatedParams = {
                                    ...currentParams,
                                    pageno: 1,
                                    purchase_by: selectedOption.value,
                                };
                                setSearchParams(new URLSearchParams(updatedParams));
                                setSelectedMember(selectedOption.value);
                                setPage(1);
                            }}
                        />
                        <div className="w-full sm:w-auto">
                            <input
                                className="form-control w-full !m-0"
                                type="date"
                                value={startDate}
                                autoComplete="off"
                                onChange={(e) => {
                                    const currentParams = Object.fromEntries(searchParams.entries());
                                    const updatedParams = {
                                        ...currentParams,
                                        pageno: 1,
                                        startdate: e.target.value,
                                    };
                                    setSearchParams(new URLSearchParams(updatedParams));
                                    setStartDate(e.target.value);
                                    setPage(1);
                                }}
                            // webkitdirectory
                            />
                        </div>
                        <p className="">To</p>
                        <div className="w-full sm:w-auto">
                            <input
                                className="form-control w-full !m-0"
                                type="date"
                                value={endDate}
                                autoComplete="off"
                                onChange={(e) => {
                                    const currentParams = Object.fromEntries(searchParams.entries());
                                    const updatedParams = {
                                        ...currentParams,
                                        pageno: 1,
                                        enddate: e.target.value,
                                    };
                                    setSearchParams(new URLSearchParams(updatedParams));
                                    setEndDate(e.target.value);
                                    setPage(1);
                                }}
                            // webkitdirectory
                            />
                        </div>
                    </div>
                </div>

                {listallCompany?.length === 0 ? (
                    <div className="no__transcation">
                        <img src={noDataFound} alt={noDataFound} loading="lazy" />
                    </div>
                ) : (<div className="connect__listing">
                    <div className="connect__data pb-2">
                        <div className="d-flex align-items-start justify-content-between connect__header min-h-[600px]">
                            <table className="table connect__list__table">
                                <thead className="report__header__border">
                                    <tr className="border-style text-capitalize">
                                        <th
                                            scope="col"
                                            className="font__weight header__first__child "
                                        >
                                            Connect Id
                                        </th>
                                        <th
                                            scope="col"
                                            className="font__weight header__first__child"
                                        >
                                            UPWork Account
                                        </th>
                                        <th scope="col" className="font__weight">
                                            Purchase By
                                        </th>
                                        <th scope="col" className="font__weight">
                                            Purchase Date
                                        </th>
                                        <th scope="col" className="font__weight">
                                            Connect
                                        </th>
                                        <th scope="col" className="font__weight">
                                            Amount
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listallCompany.map((item, index) => (
                                        <tr key={index}>
                                            <td className="table_tr_style align-middle">{item.id}</td>
                                            <td className="table_tr_style align-middle text-capitalize">
                                                {item.upwork_account}
                                            </td>
                                            <td className="align-middle text-capitalize">
                                                {item.purchase_by}
                                            </td>
                                            <td className="align-middle text-capitalize">
                                                {item.purchase_date}
                                            </td>
                                            <td className="align-middle text-capitalize">
                                                {item.connect}
                                            </td>
                                            <td className="align-middle text-capitalize">
                                                {item.amount}
                                            </td>
                                            <td className="text-center align-middle d-flex justify-content-end !border-b-0 ">
                                                {role_accesses && (
                                                    <button
                                                        className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer ms-2"
                                                        onClick={() => handleEdit(item)}
                                                        disabled={!role_accesses}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {
                            <CustomPagination
                                page={page}
                                pageInformation={pageInformation}
                                setPage={setPage}
                                totalPages={pageInformation?.last_page}
                            />
                        }
                    </div>
                </div>)}

                <Modal
                    className="connect-modal "
                    title={editedId ? "Edit Connect" : "Add Connect"}
                    onClose={() => {
                        setlistShow(false);
                        setEditedId(null);
                    }}
                    show={listshow}
                >
                    <div className="modal-body add__connect__form">
                        <div className="mt-2">
                            <h6 className="modal-heading">Account</h6>
                            <Select
                                className="basic-single text-capitalize Employ_Country "
                                classNamePrefix="select"
                                placeholder="Account"
                                name="selectedConnect"
                                options={accountList}
                                value={selectedAccount}
                                onChange={(selectedOption) => {
                                    setSelectedAccount(selectedOption);
                                }}
                            />
                        </div>
                        <div>
                            <h6 className="modal-heading mt-2">Connect</h6>
                            <input
                                placeholder="add Connect"
                                className="connect__name text-capitalize "
                                type="number"
                                value={connectNumber}
                                autoComplete="off"
                                onChange={(e) => {
                                    setConnectNumber(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <h6 className="modal-heading mt-2">Purchase Date</h6>
                            <input
                                placeholder="MM/DD/YYYY"
                                className="connect__name"
                                type="date"
                                value={purchaseDate}
                                onChange={(e) => {
                                    setPurchaseDate(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <h6 className="modal-heading mt-2">amount</h6>
                            <input
                                placeholder="add Amount"
                                className="connect__name text-capitalize "
                                type="number"
                                value={amount}
                                autoComplete="off"
                                onChange={(e) => {
                                    setAmount(e.target.value);
                                }}
                            />
                        </div>
                        <div className="add__close__section mt-3">
                            {role_accesses && (
                                <button
                                    type="button"
                                    className="connect__save__btn"
                                    onClick={handleAddCompany}
                                    disabled={!role_accesses}
                                >
                                    {editedId ? "Update" : "Add"}
                                </button>
                            )}
                        </div>
                    </div>
                </Modal>

            </div>
        </div>
    );
};

export default Connect;
