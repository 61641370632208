import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./ProjectDetails.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Documents } from "./Documents";
import Payment from "./Payment";
import Members from "./Members";
import Updates from "./Updates";
import Milestone from "./Milestone";
import Dashbord from "./Dashbord";
import TaskList from "./ProjectTask/TaskList";
import TasksRequest from "./ProjectTask/TasksRequest";
import { getRequest } from "../../../components/axiosClient";
import { GET_PROJECT_PENDING_REQUEST_COUNT } from "../../../components/utils";
import { errorNotification } from "../../../components/toast-notification/common-toast";
import { boundaryContext } from '../../../App';

const ProjectDetails = () => {

  const navigate = useNavigate();
  const id = useParams();
  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";
  const role = secureLocalStorage.getItem("role");
  const [activeTab, setActiveTab] = useState(null);
  const [count, setCount] = useState();
  const { setProjectName } = useContext(boundaryContext);

  useEffect(() => {
    const savedTab = secureLocalStorage.getItem("activeTab");
    if (savedTab) {
      setActiveTab(savedTab);
    } else {
      setActiveTab("dashboard");
    }
    getRequestCount();
  }, []);

  const getRequestCount = async () => {
    try {
      const res = await getRequest(
        `${GET_PROJECT_PENDING_REQUEST_COUNT}?project_id=${id.id}`,
        null,
        navigate
      );
      if (res.type === 2) {
        errorNotification(res.errormessage);
      } else {
        setCount(res.data.data.pendingRequest);
        setProjectName(res?.data?.data?.project_name);
      }
    } catch (error) {
      errorNotification(error);
    }
  }

  useEffect(() => {
    if (activeTab) {
      secureLocalStorage.setItem("activeTab", activeTab);
    }
  }, [activeTab]);

  // Handle tab change
  const handleTabChange = (e) => {
    setActiveTab(e.target.name);
  };

  // Navigate to previous page
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="admin__program__manage admin__program__details__section admin_audit">
      <div className="go__back__btn cursor-pointer flex justify-start items-center mb-3 gap-2">
        <ArrowBackIcon onClick={goBack} loading="lazy" />
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "dashboard" ? "active" : ""}`}
              id="pills-dashboard-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-dashboard"
              type="button"
              role="tab"
              aria-controls="pills-dashboard"
              aria-selected="true"
              onClick={(e) => handleTabChange(e)}
              name="dashboard"
            >
              Dashboard
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "scopetime" ? "active" : ""}`}
              id="pills-scopetime-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-scopetime"
              type="button"
              role="tab"
              aria-controls="pills-scopetime"
              aria-selected="false"
              name="scopetime"
              onClick={(e) => handleTabChange(e)}
            >
              Daily updates
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "tasklist" ? "active" : ""}`}
              id="pills-tasklist-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-tasklist"
              type="button"
              role="tab"
              aria-controls="pills-tasklist"
              aria-selected="false"
              name="tasklist"
              onClick={(e) => handleTabChange(e)}
            >
              Tasks
            </button>
          </li>
          {role_accesses &&
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "vulnerabilities" ? "active" : ""
                  }`}
                id="pills-vulnerabilities-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-vulnerabilities"
                type="button"
                role="tab"
                aria-controls="pills-vulnerabilities"
                aria-selected="false"
                name="vulnerabilities"
                onClick={(e) => handleTabChange(e)}
              >
                Milestone
              </button>
            </li>
          }
          {(role_accesses || role == 'Business development manager' || role == 'Business development executive') &&
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === "pentestteam" ? "active" : ""
                  }`}
                id="pills-pentestteam-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-pentestteam"
                type="button"
                role="tab"
                aria-controls="pills-pentestteam"
                aria-selected="false"
                name="pentestteam"
                onClick={(e) => handleTabChange(e)}
              >
                Payment
              </button>
            </li>
          }
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "summaryreports" ? "active" : ""
                }`}
              id="pills-summaryreports-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-summaryreports"
              type="button"
              role="tab"
              aria-controls="pills-summaryreports"
              aria-selected="false"
              name="summaryreports"
              onClick={(e) => handleTabChange(e)}
            >
              Documents
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "members" ? "active" : ""}`}
              id="pills-members-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-members"
              type="button"
              role="tab"
              aria-controls="pills-members"
              aria-selected="false"
              name="members"
              onClick={(e) => handleTabChange(e)}
            >
              Members
            </button>
          </li>
          {role_accesses && <li className="nav-item text-center" role="presentation">
            <button
              className={`nav-link ${activeTab === "task-request" ? "active" : ""}`}
              id="pills-task-request-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-task-request"
              type="button"
              role="tab"
              aria-controls="pills-task-request"
              aria-selected="false"
              name="task-request"
              onClick={(e) => handleTabChange(e)}
            >
              Task Request
              {
                count > 0 &&
                <span className="bg-red-500 inline-block text-white rounded-full h-4 w-4 text-center text-xs ml-1">{count}</span>
              }
            </button>
          </li>}
        </ul>
      </div>
      <div className="program_scroll">
        <div className="tab-content" id="pills-tabContent">
          <div
            className={`tab-pane fade ${activeTab === "dashboard" ? "show active" : ""
              }`}
            id="pills-dashboard"
            role="tabpanel"
            aria-labelledby="pills-dashboard-tab"
          >
            <div>
              {activeTab === "dashboard" && <Dashbord projectId={id.id} />}
            </div>
          </div>
          <div
            className={`tab-pane fade ${activeTab === "scopetime" ? "show active" : ""
              }`}
            id="pills-scopetime"
            role="tabpanel"
            aria-labelledby="pills-scopetime-tab"
          >
            {activeTab === "scopetime" && <Updates projectId={id.id} />}
          </div>
          <div
            className={`tab-pane fade ${activeTab === "tasklist" ? "show active" : ""
              }`}
            id="pills-tasklist"
            role="tabpanel"
            aria-labelledby="pills-tasklist-tab"
          >
            {activeTab === "tasklist" && <TaskList projectId={id.id} />}
          </div>
          <div
            className={`tab-pane fade ${activeTab === "vulnerabilities" ? "show active" : ""
              }`}
            id="pills-vulnerabilities"
            role="tabpanel"
            aria-labelledby="pills-vulnerabilities-tab"
          >
            <div className="not__item__saved">
              {activeTab === "vulnerabilities" && (
                <Milestone projectId={id.id} />
              )}
            </div>
          </div>
          <div
            className={`tab-pane fade ${activeTab === "pentestteam" ? "show active" : ""
              }`}
            id="pills-pentestteam"
            role="tabpanel"
            aria-labelledby="pills-pentestteam-tab"
          >
            <div className="not__item__saved">
              {activeTab === "pentestteam" && <Payment projectId={id.id} />}
            </div>
          </div>
          <div
            className={`tab-pane fade ${activeTab === "summaryreports" ? "show active" : ""
              }`}
            id="pills-summaryreports"
            role="tabpanel"
            aria-labelledby="pills-summaryreports-tab"
          >
            <div className="no__data__found">
              {activeTab === "summaryreports" && (
                <Documents projectId={id.id} />
              )}
            </div>
          </div>
          <div
            className={`tab-pane fade ${activeTab === "members" ? "show active" : ""
              }`}
            id="pills-members"
            role="tabpanel"
            aria-labelledby="pills-members-tab"
          >
            <div className="not__item__saved">
              {activeTab === "members" && <Members projectId={id.id} />}
            </div>
          </div>
          <div
            className={`tab-pane fade ${activeTab === "task-request" ? "show active" : ""
              }`}
            id="pills-task-request"
            role="tabpanel"
            aria-labelledby="pills-task-request-tab"
          >
            <div className="not__item__saved">
              {activeTab === "task-request" && <TasksRequest projectId={id.id} setCount={setCount} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
