import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import DayWiseReport from "./DayWiseReport";
import EmployeeWiseReport from "./EmployeeWiseReport";

export default function Timesheet({ data }) {
  return (
    <Tabs defaultActiveKey="day" id="report-tabs" className="nav-pills mb-3">
      <Tab eventKey="day" title="Day" className="nav-link">
        <DayWiseReport />
      </Tab>
      <Tab eventKey="employee" title="Employee" className="nav-link">
        <EmployeeWiseReport />
      </Tab>
    </Tabs>
  );
}
