import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaStop } from "react-icons/fa6"
import { IoIosCheckmark } from 'react-icons/io'
import { useNavigate, useSearchParams } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Select from "react-select"
import noDataFound from "../../../components/assets/No data-rafiki 1.svg"
import clock_inActive from '../../../components/assets/clock_inActive.svg'
import high from '../../../components/assets/high_priority.svg'
import low from '../../../components/assets/low_priority.svg'
import normal from '../../../components/assets/normal_priority.svg'
import RequestIconActive from '../../../components/assets/request_icon_active.svg'
import start_button from '../../../components/assets/start-icon.svg'
import { getRequest } from '../../../components/axiosClient'
import LoadingSpinner from '../../../components/loader/LoadingSpinner'
import { errorNotification } from '../../../components/toast-notification/common-toast'
import { GET_USER_TASKS } from '../../../components/utils'
import { Tooltip } from '@mui/material'

const AllTaskList = React.memo(({ onStart, handlePause, runningTask, handleRequestModal, projectList, handleTaskComplete }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [pageInformation, setPageInformation] = useState({});
    const pageSize = 30;
    const [page, setPage] = useState(1);
    const [taskList, setTaskList] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const isMounted = useRef(false);
    const taskListRef = useRef();

    const [searchParams, setSearchParams] = useSearchParams();

    const projectParams = searchParams.get('project');
    const [projectFilter, setProjectFilter] = useState(projectParams ? parseInt(projectParams) : null);

    const statusParams = searchParams.get('status');
    const [statusFilter, setStatusFilter] = useState(statusParams ? statusParams : null);

    const priorityParams = searchParams.get('priority');
    const [priorityFilter, setPriorityFilter] = useState(priorityParams ? priorityParams : null);

    const handleTaskView = (id, projectId) => {
        if (projectId) {
            navigate(`/my-task/task-details/${id}?project_id=${projectId}`);
        } else {
            navigate(`/my-task/task-details/${id}`);
        }
    };

    useEffect(() => {
        if (!isMounted.current) {
            getTaskList();
            isMounted.current = true;
        }
    }, [projectFilter, statusFilter, priorityFilter]);

    const getTaskList = async () => {
        try {
            // setFirstLoading(true);
            let apiUrl = `${GET_USER_TASKS}?page=${1}&limit=${pageSize}`;
            if (projectFilter && projectFilter !== "All") {
                apiUrl += `&project_id=${projectFilter}`;
            }
            if (statusFilter && statusFilter !== "All") {
                apiUrl += `&status=${statusFilter}`;
            }
            if (priorityFilter && priorityFilter !== "All") {
                apiUrl += `&priority=${priorityFilter}`;
            }
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                setTaskList(res.data.data.page_data);
                setPageInformation(res.data.data.page_information);
                setPage(2);
            } else {
                setHasMoreData(false);
                errorNotification(res.errormessage);
                setTaskList([]);
            }
            setFirstLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setFirstLoading(false);
        }
    }


    const fetchData = useCallback(async () => {
        if (loading || pageInformation?.current_page >= pageInformation?.last_page) return;

        setLoading(true);
        try {
            let apiUrl = `${GET_USER_TASKS}?page=${page}&limit=${pageSize}`;
            if (projectFilter && projectFilter !== "All") {
                apiUrl += `&project_id=${projectFilter}`;
            }
            if (statusFilter && statusFilter !== "All") {
                apiUrl += `&status=${statusFilter}`;
            }
            if (priorityFilter && priorityFilter !== "All") {
                apiUrl += `&priority=${priorityFilter}`;
            }
            const res = await getRequest(apiUrl, null, navigate);
            if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
                const newPageData = res.data.data.page_data;
                setTaskList((preData) => [...preData, ...newPageData]);
                setPageInformation(res.data.data.page_information);
                setPage((prevPage) => prevPage + 1);
            }
            if (res.data.data.page_information.current_page >= res.data.data.page_information.last_page) {
                setHasMoreData(false); // No more data available
            }

        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false); // Stop loading
        }
    }, [page, loading, hasMoreData]);

    const handleScroll = () => {
        if (taskListRef.current) {
            const { scrollTop, clientHeight, scrollHeight } = taskListRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 200) {
                if (!loading) {
                    fetchData();
                }
            }
        }
    };

    useEffect(() => {
        const divElement = taskListRef.current;
        let debounceTimeout;

        const handleDebouncedScroll = () => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(handleScroll, 200);
        };

        if (divElement) {
            divElement.addEventListener("scroll", handleDebouncedScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("scroll", handleDebouncedScroll);
            }
            clearTimeout(debounceTimeout);
        };
    }, [fetchData, loading]);


    const customIcons = {
        "High": <img src={high} alt="High" style={{ width: '14px', height: '14px' }} />,
        "Medium": <img src={normal} alt="Medium" style={{ width: '14px', height: '14px' }} />,
        "Low": <img src={low} alt="Low" style={{ width: '14px', height: '14px' }} />
    };

    const projectFilterOptions = [
        { label: "All", value: "All" },
        ...projectList
    ]

    const statusFilterOptionList = ["All", "To do", "In progress", "To be verified", "Completed"];
    const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Project Lead")?.access_type !== "None";

    if (role_accesses) {
        statusFilterOptionList.push("Open", "In Meeting", "Confirm-Sale", "Closed");
    }
    const statusFilterOption = statusFilterOptionList.map((props) => ({
        label: props,
        value: props
    }));

    const priorityOptionsList = ["All", "Low", "Medium", "High"];
    const priorityFilterOption = priorityOptionsList.map((props) => ({
        label: props,
        value: props
    }));

    return firstLoading ? (
        <LoadingSpinner />
    ) : (
        <div className='pb-2 px-1 max-h-[calc(100vh_-330px)] overflow-y-auto' ref={taskListRef}>
            <div className='flex flex-row justify-between items-center px-1 mb-2'>
                {/* <span className='px-2 text-2xl font-semibold'>My Activity</span> */}
                <span></span>
                <div className='flex justify-end items-center gap-4 '>
                    <Select
                        className="basic-single md:md:my-3 !min-w-36"
                        classNamePrefix="select"
                        placeholder="Project"
                        name="Project"
                        options={projectFilterOptions}
                        value={projectFilterOptions.find(option => option.value === projectFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                project: selectedOption.value,
                            };
                            isMounted.current = false;
                            setSearchParams(new URLSearchParams(updatedParams));
                            setProjectFilter(selectedOption.value);
                            setPage(1);
                        }}
                    />
                    <Select
                        className="basic-single text-capitalize md:my-3 !min-w-36"
                        classNamePrefix="select"
                        placeholder="Status"
                        name="Status"
                        options={statusFilterOption}
                        value={statusFilterOption.find(option => option.value === statusFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                status: selectedOption.value,
                            };
                            isMounted.current = false;
                            setSearchParams(new URLSearchParams(updatedParams));
                            setStatusFilter(selectedOption.value);
                            setPage(1);
                        }}
                    />
                    <Select
                        className="basic-single md:my-3 !min-w-36"
                        classNamePrefix="select"
                        placeholder="Priority"
                        name="Priority"
                        options={priorityFilterOption}
                        value={priorityFilterOption.find(option => option.value === priorityFilter)}
                        onChange={(selectedOption) => {
                            const currentParams = Object.fromEntries(searchParams.entries());
                            const updatedParams = {
                                ...currentParams,
                                pageno: 1,
                                priority: selectedOption.value,
                            };
                            isMounted.current = false;
                            setSearchParams(new URLSearchParams(updatedParams));
                            setPriorityFilter(selectedOption.value);
                            setPage(1);
                        }}
                    />
                </div>
            </div>
            {taskList?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div>
                    <div className='min-h-[500px] flex flex-col justify-between overflow-x-auto overflow-y-auto' >
                        <table className="table max-w-full">
                            <thead className="">
                                <tr className="align-middle">
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium ">
                                        Name
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Project
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Due Date
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Status
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Priority
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">
                                        Total Time
                                    </th>
                                    <th scope="col" className="!border-b-[#EFEFEF] font-medium">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    taskList?.map((item, index) => {
                                        const timeDuration = dayjs.duration(item.duration, 'seconds');
                                        const hours = String(timeDuration.hours()).padStart(2, '0');
                                        const minutes = String(timeDuration.minutes()).padStart(2, '0');
                                        const formattedTime = `${hours}:${minutes}`;
                                        return (
                                            <tr className={`align-middle border-b-0 hover:bg-[#f7f7f7] hover:rounded-lg overflow-hidden cursor-pointer ${item?.is_completed ? 'opacity-50' : 'opacity-100'}`}
                                                onClick={(e) => {
                                                    if (window.getSelection().toString() === '') {
                                                        // If no text is selected, handle the task view
                                                        handleTaskView(item.id, item?.project?.id);
                                                    }
                                                }}
                                                key={index}
                                            >
                                                <td className="border-b-0 !border-b-[#EFEFEF] p-2 !max-w-[30vw] overflow-hidden">
                                                    <div className='flex items-center w-full gap-1'>
                                                        <div className={`rounded-full border flex items-center 
                                                                            ${!item?.is_completed ? 'bg-white text-black' : 'bg-green-600 text-white'} cursor-pointer`}
                                                            onClick={async (e) => {

                                                                e.stopPropagation();
                                                                await handleTaskComplete(item.id, item?.is_completed);
                                                                await getTaskList();
                                                            }}
                                                        >
                                                            <IoIosCheckmark />
                                                        </div>
                                                        <div className="truncate">
                                                            {item?.title || "--"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF] truncate">
                                                    {item?.project?.name || "--"}
                                                </td>
                                                <td className="border-b-0 !border-b-[#EFEFEF] w-[10%]">
                                                    {item?.due_date ? dayjs(item.due_date).format("DD-MM-YYYY") : "--"}
                                                </td>
                                                <td className="table_tr_style align-middle">
                                                    <div className={`${item?.status === "In progress" ? "text-[#db763b] bg-[#eeb08c3d]" : item?.status === "Completed" ? "text-[#83BF6E] bg-[#83bf6e24]" : item?.status === "To be verified" ? "text-[#8E59FF] bg-[#8e59ff1a]" : item?.status === "To do" ? "text-[#6F767E] bg-[#6f767e2b]" : item?.status === "Confirm-Sale"
                                                        ? "text-[#6F767E] bg-[#6F767E24]"
                                                        : item?.status === "Open"
                                                            ? "text-[#83BF6E] bg-[#83bf6e24]"
                                                            : item?.status === "Closed"
                                                                ? "text-[#EE404C] bg-[#ff00001a]" : item?.status === "In Meeting" ? "text-[#8E59FF] bg-[#8e59ff1a]" : "text-[#6F767E] bg-[#6f767e2b]"}
                                                py-[6px] px-[12px] rounded-[6px] text-base whitespace-nowrap text-capitalize w-[120px] d-flex justify-center`}>
                                                        {item?.status}
                                                    </div>
                                                </td>
                                                <td className="table_tr_style align-middle w-[10%]">
                                                    {
                                                        item?.priority ?
                                                            <span className={`flex flex-row gap-1 justify-start items-center`}>
                                                                {customIcons[item?.priority]} {item?.priority}
                                                            </span>
                                                            : '--'
                                                    }
                                                </td>
                                                <td className="table_tr_style align-middle">
                                                    <div className="flex flex-row justify-start items-center gap-1 max-w-full">
                                                        <img src={clock_inActive} alt="Clock Icon" className="w-4 h-4 min-w-[16px]" />
                                                        <span className="w-[40px]">{formattedTime}</span>
                                                    </div>
                                                </td>

                                                <td className="border-b-0 !border-b-[#EFEFEF] text-right w-[15%]">
                                                    <div className='flex flex-row items-center gap-2 justify-end'>
                                                        {
                                                            runningTask && runningTask?.task.id === item.id ?
                                                                <button className='flex flex-row items-center gap-1 border justify-start text-black rounded-md p-2 hover:!border-black w-[110px]'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handlePause()
                                                                    }}
                                                                >
                                                                    <FaStop className='h-[12px]' /> Stop
                                                                </button>
                                                                :
                                                                <button className='flex flex-row items-center gap-1 border justify-start text-black rounded-md p-2  hover:!border-black w-[110px]'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        onStart(item, e)
                                                                    }}
                                                                >
                                                                    <img className='h-[11px] w-[11px]' src={start_button} alt="start_button" /> {item?.duration != 0 ? "Continue" : "Start"}
                                                                </button>
                                                        }
                                                        <Tooltip title="Add manual hours">
                                                            <img src={RequestIconActive} alt='!!' className='h-5 w-5 cursor-pointer'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleRequestModal(item);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        {
                            loading &&
                            <div className='w-full my-2 flex items-center justify-center'>
                                Loading...
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    )
});

export default AllTaskList