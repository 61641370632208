import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import validator from "validator";
import "./ProjectListing.css";
import {
  errorNotification,
  successNotification,
} from "../../../components/toast-notification/common-toast";
import {
  ADD_PROJECT,
  GET_PROJECT,
  UPDATE_PROJECT,
  GET_CLIENT_DROPDOWN_LIST,
  ADD_CLIENT,
} from "../../../components/utils";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../components/axiosClient";
import LoadingSpinner from "../../../../src/components/loader/LoadingSpinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../../../components/common/CustomPagination";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import secureLocalStorage from "react-secure-storage";
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import dayjs from "dayjs";
import debounce from 'lodash.debounce';



const ProjectListing = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    rate: "",
    estimated_budget: "",
    status: "",
    deadline: "",
    start_date: "",
    estimated_hours: "",
    selectedType: null,
    selectedClient: null,
  });
  const [editedId, setEditedId] = useState(null);
  const [listshow, setlistShow] = useState(false);
  const [addNewClient, setAddNewClient] = useState(false);
  const [listAllProject, setListAllProject] = useState([]);
  const [clientName, setClientName] = useState("");
  const [pageInformation, setPageInformation] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);

  const [inputFocus, setInputFocus] = useState(false);
  const pageSize = 10;
  const navigate = useNavigate();
  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read"
  const inputRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = searchParams.get('pageno');
  const [page, setPage] = useState(pageNo ? parseInt(pageNo, 10) : 1);

  const statusQuery = searchParams.get('status');
  const [statusFilter, setStatusFilter] = useState((statusQuery !== null && statusQuery !== undefined) ? {
    label: statusQuery,
    value: statusQuery,
  } : null);

  const searchQuery = searchParams.get('search') || '';
  const [search, setSearch] = useState(searchQuery);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      ["rate", "estimated_budget", "estimated_hours"].includes(name) &&
      value < 0
    ) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    let ratePlaceholder = "Rate";
    if (actionMeta.name === "selectedType" && selectedOption.value === "hourly") {
      ratePlaceholder = "Hourly Rate";
    } else if (actionMeta.name === "selectedType" && selectedOption.value === "monthly") {
      ratePlaceholder = "Monthly Rate";
    }

    setFormData((prevState) => ({
      ...prevState,
      [actionMeta.name]: selectedOption,
      rate: actionMeta.name === "selectedType" && selectedOption.value === "fixed" ? "" : prevState.rate,
      ratePlaceholder: ratePlaceholder, // Update ratePlaceholder here
    }));
  };


  //  ADD_PROJECT
  const addProject = async (payload) => {
    try {
      const res = await postRequest(ADD_PROJECT, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          getAllProject();
          successNotification(res.response.data.message);
          setFormData({
            name: "",
            rate: "",
            estimated_budget: "",
            status: "",
            deadline: "",
            start_date: "",
            estimated_hours: "",
            selectedType: null,
            selectedClient: null,
          });
          setlistShow(false);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  const handleAdd = () => {
    setFormData({
      name: "",
      rate: "",
      estimated_budget: "",
      status: "",
      deadline: "",
      start_date: "",
      estimated_hours: "",
      selectedType: null,
      selectedClient: null,
    });
    setEditedId(null);
    setlistShow(true);
  };

  // UPDATE_PROJECT
  const editClientData = async (payload) => {
    try {
      const res = await patchRequest(UPDATE_PROJECT, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          getAllProject();
          successNotification(res.response.data.message);
          setFormData({
            name: "",
            rate: "",
            estimated_budget: "",
            status: "",
            deadline: "",
            start_date: "",
            estimated_hours: "",
            selectedType: null,
            selectedClient: null,
          });
          setlistShow(false);
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  const handleAddProject = (e) => {
    e.preventDefault();
    // Basic validations
    if (validator.isEmpty(formData.name)) {
      errorNotification("Please Enter Project Name");
    } else if (!formData.selectedClient || !formData.selectedClient.value) {
      errorNotification("Please Select Client Name");
    } else if (!formData.selectedType || !formData.selectedType.value) {
      errorNotification("Please Select Project Type");
    } else if (
      formData.selectedType.value !== "fixed" &&
      (validator.isEmpty(formData.rate) ||
        !validator.isFloat(formData.rate, { min: 0 }))
    ) {
      errorNotification("Please Enter Project Rate");
    } else if (
      validator.isEmpty(formData.estimated_budget) ||
      !validator.isFloat(formData.estimated_budget, { min: 0 })
    ) {
      errorNotification("Please Enter Estimated Budget");
    } else if (!formData.start_date || validator.isEmpty(formData.start_date)) {
      errorNotification("Please Select Start Date");
    } else if (formData.start_date && !validator.isDate(formData.start_date, new Date())) {
      errorNotification("please Enter valid start date");
    } else if (formData.end_date && !validator.isDate(formData.end_date, new Date())) {
      errorNotification("please Enter valid end date");
    }
    else {
      // Prepare the data for submission
      let companyData = {
        name: formData.name,
        type: formData.selectedType.value,
        rate: formData.selectedType.value === "fixed" ? null : formData.rate,
        estimated_budget: formData.estimated_budget,
        status: formData.status.value,
        deadline: formData.deadline,
        start_date: formData.start_date,
        estimated_hours: formData.estimated_hours.trim() === "" ? null : formData.estimated_hours === "null" ? null : formData.estimated_hours,
        client_id: formData.selectedClient
          ? formData.selectedClient.value
          : null,
      };
      if (companyData.estimated_hours === null) {
        companyData.estimated_hours = 0;
      }
      // If editing, add the project ID
      if (editedId) {
        companyData.project_id = editedId;
      }

      // Call the appropriate function to add or update the project
      editedId ? editClientData(companyData) : addProject(companyData);
    }
  };


  // useEffect(() => {
  //   setSearch(searchQuery);
  //   setStatusFilter((statusQuery !== null && statusQuery !== undefined) ? {
  //     label: statusQuery,
  //     value: statusQuery,
  //   } : null);
  //   setPage(pageNo ? parseInt(pageNo, 10) : 1);
  // }, [searchQuery, statusQuery, pageNo]);

  const handleSearchChange = (event) => {
    const newSearch = event.target.value;
    setSearch(newSearch);
    setPage(1);

    const currentParams = Object.fromEntries(searchParams.entries());
    const updatedParams = {
      ...currentParams,
      pageno: 1,
      search: newSearch,
    };
    setSearchParams(new URLSearchParams(updatedParams));
  };


  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 500);
  }, []);

  // useEffect(() => {
  //   return () => {
  //     debouncedResults.cancel();
  //   };
  // });

  useEffect(() => {
    if (role_accesses) {
      getClientOptions();
    }
    getAllProject();
  }, [page, search, statusFilter, role_accesses]);

  const handleEdit = (item) => {
    setFormData({
      name: item.name,
      type: item.type,
      rate: item.type === "fixed" ? "" : String(item.rate),
      estimated_budget: String(item.estimated_budget),
      start_date: item.start_date,
      deadline: item.deadline,
      estimated_hours: String(item.estimated_hours),
      status: { label: item.status, value: item.status },
      selectedType: { label: item.type, value: item.type },
      selectedClient: { label: item.client.name, value: item.client.id },
    });
    setEditedId(item.id);
    setlistShow(true);
  };

  const handleView = (item) => {
    navigate(`/project-list/project-details/${item.id}`);
  }

  // GET_PROJECT
  const getAllProject = async () => {
    try {
      setLoading(true);
      let apiUrl = `${GET_PROJECT}?page=${page}&limit=${pageSize}`;
      if (search && search !== '') {
        apiUrl += `&name=${search}`;
      }
      if (statusFilter && statusFilter.value !== "All" && statusFilter.value !== null) {
        apiUrl += `&status=${statusFilter.value}`;
      }
      const res = await getRequest(
        apiUrl,
        null,
        navigate
      );
      if (res.data && res.data.data && Array.isArray(res.data.data.page_data)) {
        setListAllProject(res.data.data.page_data);
        setPageInformation(res.data.data.page_information);
      } else {
        errorNotification(res.errormessage);
        setListAllProject([]);
      }
      setLoading(false);
    }
    catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };

  // GET_CLIENT_DROPDOWN_LIST
  const getClientOptions = async () => {
    try {
      const res = await getRequest(GET_CLIENT_DROPDOWN_LIST, null, navigate);
      if (res.data && Array.isArray(res.data.data)) {
        const options = res.data.data.map((client) => ({
          label: client.name,
          value: client.id,
        }));
        setClientOptions(options);
      } else {
        setClientOptions([]);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  const typeArray = ["hourly", "fixed", "monthly"];

  const typeOptions = typeArray.map((type) => ({
    label: type,
    value: type,
  }));

  const statusArray = ["In Progress", "Complete", "Overdue", "Cancelled"];

  const statusOptions = statusArray.map((type) => ({
    label: type,
    value: type,
  }));

  const filterStatusArray = ["All", "In Progress", "Complete", "Overdue", "Cancelled"];

  const filterStatusOptions = filterStatusArray.map((type) => ({
    label: type,
    value: type,
  }));

  //handle add btn click
  const handleOpenClientModal = () => {
    setClientName(""); // Clear the client name input
    setSelectedCountry(null); // Clear the selected country
    setEditedId(null); // Reset editedId to null
    setAddNewClient(true);
  };

  // for new add client API Call
  const addClient = async (payload) => {
    try {
      const res = await postRequest(ADD_CLIENT, payload, navigate);
      if (res.type === 1) {
        if (res.response.data.success) {
          successNotification("Client Added Successfully");
          setClientName("");
          setSelectedCountry(null);
          setAddNewClient(false);
          setlistShow(true);
          getClientOptions();
        } else {
          errorNotification(res.response.data.message);
        }
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  // for new add client
  const handleAddClient = (e) => {
    e.preventDefault();

    if (validator.isEmpty(clientName)) {
      errorNotification("Please Enter Client Name");
      setlistShow(true);
    } else if (!selectedCountry) {
      errorNotification("Please Select Country Name");
      setlistShow(true);
    } else {
      let companyData = {
        name: clientName,
        country: selectedCountry.value,
      };
      addClient(companyData);
      setlistShow(true);
    }
  };

  const formateHours = (time) => {
    if (time === null || time === undefined) {
      return null;
    }

    // Convert the string to a number representing seconds
    const totalSeconds = parseInt(time, 10);

    if (isNaN(totalSeconds)) {
      return null; // Return null if the input is not a valid number
    }

    // Convert seconds to hours
    const totalHours = totalSeconds / 3600;
    return Math.round(totalHours);  // Round to the nearest whole hour
  };


  useEffect(() => {
    // Set default value of deadline to current date if it is empty
    const today = new Date().toISOString().split("T")[0];
    if (!formData.deadline) {
      setFormData((prevState) => ({
        ...prevState,
        deadline: today,
      }));
    }
  }, [formData.deadline]);

  const minDate = new Date().toISOString().split("T")[0];

  const countryArray = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burma (Myanmar)",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Gaza Strip",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See (Vatican City)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of the Congo",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "US Virgin Islands",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna",
    "West Bank",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const countryOptions = countryArray.map((country) => ({
    label: country,
    value: country,
  }));

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="project__section bg-[#fff] rounded-[12px] p-[10px]">
      <div className="w-full project__subsection">
        <div className="w-full flex flex-row justify-between items-center">

          {role_accesses && (
            <button
              className="add__employee mx-3 mt-1"
              onClick={handleAdd}
              disabled={!role_accesses}
            >
              + Add Project
            </button>
          )}
          <div className="flex flex-row justify-center gap-6 items-center mx-3 mt-1">
            <input
              type="search"
              className="project_search"
              autoFocus={inputFocus ? true : false}
              defaultValue={search}
              onChange={(e) => {
                setInputFocus(true);
                debouncedResults(e);
              }}
              placeholder="Search projects"
              ref={inputRef}
            />
            <Select
              className="basic-single !min-w-[175px]  text-capitalize my-3 "
              classNamePrefix="select"
              placeholder="Status Filter"
              name="status"
              options={filterStatusOptions}
              value={statusFilter}
              onChange={(selectedOption) => {
                setStatusFilter(selectedOption);
                setPage(1);
                const currentParams = Object.fromEntries(searchParams.entries());
                const updatedParams = {
                  ...currentParams,
                  pageno: 1,
                  status: selectedOption.value,
                };
                setSearchParams(new URLSearchParams(updatedParams));
              }}
            />
          </div>
        </div>
        {listAllProject?.length === 0 ? (
          <div className="no__transcation">
            <img src={noDataFound} alt={noDataFound} loading="lazy" />
          </div>
        ) : (
          <div className="project__listing">
            <div className="project__data pb-4">
              <div className="d-flex flex-column flex-md-row align-items-start justify-content-between min-h-[600px] project__data_table ">
                <table className="table project__list__table table-responsive">
                  <thead className="report__header__border">
                    <tr className="border-style">
                      <th scope="col" className="font__weight header__first__child">No</th>
                      <th scope="col" className="font__weight header__first__child">Project Name</th>
                      <th scope="col" className="font__weight">Type</th>
                      <th scope="col" className="font__weight">Deadline</th>
                      <th scope="col" className="font__weight">Hours</th>
                      <th scope="col" className="font__weight">Status</th>
                      <th scope="col" className="font__weight"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listAllProject.map((item, index) => (
                      <tr key={index}>
                        <td className="table_tr_style align-middle">P{item?.id}</td>
                        <td className="table_tr_style align-middle text-capitalize">{item.name}</td>
                        <td className="align-middle text-capitalize">{item.type}</td>
                        <td className="align-middle">{item.deadline ? dayjs(item.deadline).format("DD-MM-YYYY") : '--'}</td>
                        <td className="align-middle">
                          {item?.estimated_hours && item?.total_duration_seconds ? formateHours(item?.total_duration_seconds) + "/" + item.estimated_hours : "--"}
                        </td>
                        <td className="align-middle">
                          <div
                            className={`
                        ${item.status === "Overdue" ? "text-[#db763b] bg-[#eeb08c3d]" : item.status === "In Progress" ? "text-[#83BF6E] bg-[#83bf6e24]" : item.status === "Complete" ? "text-[#8E59FF] bg-[#8e59ff1a]" : item.status === "Cancelled" ? "text-[#ff3131] bg-[#f5727225]" : "text-[#83BF6E] bg-[#83bf6e24]"}
                        py-[6px] px-[12px] rounded-[6px] text-[16px] whitespace-nowrap text-capitalize w-[100px] d-flex justify-center
                      `}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td className="text-center align-middle !border-b-0 d-flex justify-content-end">
                          <button
                            className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                            onClick={() => handleView(item)}
                          >
                            View
                          </button>
                          {role_accesses && (
                            <>
                              <button
                                disabled={!role_accesses}
                                className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                                onClick={() => handleEdit(item)}
                              >
                                Edit
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <CustomPagination
                page={page}
                pageInformation={pageInformation}
                setPage={setPage}
                totalPages={pageInformation?.last_page}
              />
            </div>
          </div>
        )
        }

        <Modal
          id="project modal"
          className="project-modal modal-heading"
          key="project"
          title={editedId ? "Edit Project" : "Add Project"}
          onClose={() => {
            setlistShow(false);
            setEditedId(null);
          }}
          show={listshow}
        >
          <div className="modal-body add__project__form">
            <Row>
              <Col xs={12}>
                <h6 className="modal-heading">Project Name</h6>
                <input
                  placeholder="Project Name"
                  className="project__name text-capitalize "
                  type="text"
                  name="name"
                  value={formData.name}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mt-3">
                <h6 className="modal-heading">Client Name</h6>
                <Select
                  options={clientOptions}
                  className="basic-single text-capitalize "
                  classNamePrefix="select"
                  value={formData.selectedClient}
                  placeholder="Client Name"
                  name="selectedClient"
                  onChange={handleSelectChange}
                />
                <div className="add-new-client d-flex justify-content-end">
                  <span className="Add_New_Client" onClick={handleOpenClientModal}>
                    + Add New Client
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6}>
                <h6 className="modal-heading">Type</h6>
                <Select
                  options={typeOptions}
                  className="basic-single text-capitalize "
                  classNamePrefix="select"
                  value={formData.selectedType}
                  placeholder="Type"
                  name="selectedType"
                  onChange={handleSelectChange}
                />
              </Col>
              <Col xs={12} md={6}>
                <h6 className="modal-heading">Rate</h6>
                <input
                  type="number"
                  name="rate"
                  placeholder={formData.ratePlaceholder || "Rate"}
                  value={formData.selectedType && formData.selectedType.value === "fixed" ? "" : formData.rate}
                  onChange={handleInputChange}
                  className="project__name "
                  disabled={formData.selectedType && formData.selectedType.value === "fixed"}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={6}>
                <h6 className="modal-heading">Estimated Hours</h6>
                <input
                  placeholder="Estimated Hours"
                  className="project__name "
                  type="number"
                  name="estimated_hours"
                  value={formData.estimated_hours}
                  autoComplete="off"
                  onChange={handleInputChange}
                // disabled={formData.selectedType && formData.selectedType.value === "monthly"}

                />
              </Col>
              <Col xs={12} md={6}>
                <h6 className="modal-heading">Estimated Budget</h6>
                <input
                  placeholder="Estimated Budget"
                  className="project__name "
                  type="number"
                  name="estimated_budget"
                  value={formData.estimated_budget}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} md={6}>
                <h6 className="modal-heading">Start date</h6>
                <input
                  placeholder="Start date"
                  className="project__name "
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  // min={minDate}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Col>
              <Col xs={12} md={6}>
                <h6 className="modal-heading">Deadline</h6>
                <input
                  placeholder="Deadline"
                  className="project__name "
                  type="date"
                  name="deadline"
                  value={formData.deadline}
                  min={minDate}
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Col>

              {
                editedId &&
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <h6 className="modal-heading">Status</h6>
                    <Select
                      options={statusOptions}
                      className="basic-single text-capitalize Employ_Country "
                      classNamePrefix="select"
                      value={formData.status}
                      placeholder="Status"
                      name="status"
                      onChange={handleSelectChange}
                    />
                  </Col>
                </Row>
              }
            </Row>
          </div>
          <Row className="add__close__section mt-3">
            <Col xs={12}>
              <button type="button" className="project__save__btn " onClick={handleAddProject}>
                {editedId ? "Update" : "Add"} Project
              </button>
            </Col>
          </Row>
        </Modal>
        <Modal
          id="client modal"
          className="client-modal"
          key="client"
          title="Add Client"
          onClose={() => {
            setAddNewClient(false);
          }}
          show={addNewClient}
        >
          <div className="modal-body add__client__form">
            <div>
              <h6 className="modal-heading">Client Name</h6>
              <input
                placeholder="Client Name"
                className="client__name text-capitalize "
                type="text"
                value={clientName}
                autoComplete="off"
                onChange={(e) => {
                  setClientName(e.target.value);
                }}
              />
            </div>
            <div className="mt-3">
              <h6 className="modal-heading">Country</h6>
              <Select
                options={countryOptions}
                className="basic-single"
                classNamePrefix="select"
                value={selectedCountry}
                placeholder="Country Name"
                name="filterhighlow"
                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
              />
            </div>
            <div className="add__close__section mt-3">
              <button type="button" className="client__save__btn  " onClick={handleAddClient}>
                Add
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>

  );
};

export default ProjectListing;
