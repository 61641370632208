import { Avatar } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { GET_MY_TEAM_TASK_TIME_LINE, GET_TASK_TIMER } from '../../../../components/utils';
import { getRequest } from '../../../../components/axiosClient';
import { useNavigate } from 'react-router-dom';
import { errorNotification } from '../../../../components/toast-notification/common-toast';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function TimeLine({ task_id, call_id }) {
    const [loading, setLoading] = useState(false);
    const [timeLineList, setTimeLineList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getTimeLineData();
    }, []);

    const getTimeLineData = async () => {
        try {
            setLoading(true);
            let apiUrl;
            if (call_id == 1) {
                apiUrl = `${GET_TASK_TIMER}?task_id=${task_id}`;
            } else if (call_id == 2) {
                apiUrl = `${GET_MY_TEAM_TASK_TIME_LINE}?task_id=${task_id}`;
            }
            const res = await getRequest(
                apiUrl,
                null,
                navigate
            );
            if (res.data && res.data.data) {
                setTimeLineList(res.data.data);
            } else {
                errorNotification(res.errormessage);
                setTimeLineList([]);
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        }
    }
    return (
        <div className='p-2 lg:h-[calc(100dvh_-_300px)] overflow-y-scroll hide-scrollbar'>
            {
                timeLineList.length <= 0 ?
                    <div className='text-center'>There is no Time Line yet.</div>
                    :
                    <table className='table max-w-full text-sm'>
                        <thead>
                            <tr className='align-middle '>
                                <th className='font-medium !border-b-[#EFEFEF]'>Who</th>
                                <th className='font-medium !border-b-[#EFEFEF]'>Date</th>
                                <th className='font-medium !border-b-[#EFEFEF]'>Start</th>
                                <th className='font-medium !border-b-[#EFEFEF]'>End</th>
                                <th className='font-medium !border-b-[#EFEFEF]'>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                timeLineList && timeLineList.map((item, index) => {

                                    const timeDuration = dayjs.duration(item.duration, 'seconds');
                                    const hours = String(timeDuration.hours()).padStart(2, '0');
                                    const minutes = String(timeDuration.minutes()).padStart(2, '0');
                                    const formattedTime = `${hours}:${minutes}`;

                                    return (
                                        <tr className='align-middle' key={index}>
                                            <td className='!border-b-[#EFEFEF]'>
                                                <span className='flex justify-start items-center gap-2'>
                                                    <Avatar
                                                        className='!h-7 !w-7'
                                                        src={item.user.profile_image}
                                                    />
                                                    {item?.user.first_name} {item?.user.last_name}
                                                </span>
                                            </td>
                                            <td className='!border-b-[#EFEFEF]'>{dayjs(item?.date).format("DD-MM-YYYY")}</td>
                                            <td className='!border-b-[#EFEFEF]'>
                                                {dayjs(item?.start_time, 'HH:mm:ss').format('hh:mm A')}
                                            </td>
                                            <td className='!border-b-[#EFEFEF]'>
                                                {item?.end_time ? dayjs(item?.end_time, 'HH:mm:ss').format('hh:mm A') : '--'}
                                            </td>
                                            <td className='!border-b-[#EFEFEF]'>{formattedTime}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
            }

            <style>
                {`
                    .hide-scrollbar {
                        -ms-overflow-style: none; /* IE and Edge */
                        scrollbar-width: none; /* Firefox */
                    }
                `}
            </style>
        </div>
    )
}

export default TimeLine