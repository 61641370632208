import { Avatar } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { getRequest, patchRequest } from "../../../components/axiosClient";
import LoadingSpinner from '../../../components/loader/LoadingSpinner';
import { GET_INBOX_LIST, UPDATE_NOTIFICATION_FLAG } from '../../../components/utils';
import { errorNotification } from '../../../components/toast-notification/common-toast';
import { useNavigate } from 'react-router-dom';
import { boundaryContext } from '../../../App';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import noDataFound from "../../../components/assets/No data-rafiki 1.svg";
import secureLocalStorage from 'react-secure-storage';

dayjs.extend(relativeTime);

const InboxAction = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const [actionData, setActionData] = useState([]);
    const { socket, inboxCount, setInboxCount, setMyTeamActiveRoute, setMyTaskActiveRoute, userData } = useContext(boundaryContext);

    useEffect(() => {
        // Fetch data on initial render
        fetchActionData();
    }, []);

    useEffect(() => {

        if (socket) {
            // Emit the 'getNotification' event
            socket.emit('getNotification');

            // Listen for real-time data and handle the received message
            socket.on('getNotification', (message) => {
                setActionData((prevData) => [message, ...prevData]);
            });
        }

        // Cleanup on component unmount
        return () => {
            if (socket) {
                socket.off('getNotification');
            }
        };
    }, [socket]);



    const fetchActionData = async () => {
        setLoading(true);
        try {
            let API_URL = `${GET_INBOX_LIST}`;
            const res = await getRequest(API_URL, null, navigate);
            if (res.data && res.data.data) {
                setActionData(res.data.data);
            } else {
                errorNotification(res.message);
            }
        } catch (e) {
            console.error('Failed to fetch action data', e);
            errorNotification(e.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const formatDate = (date) => {
        const now = dayjs();             // Current time
        const createdAt = dayjs(date);   // Notification time

        // Calculate the difference in hours and minutes
        const diffInMinutes = now.diff(createdAt, 'minute');
        const diffInHours = Math.floor(diffInMinutes / 60);
        const remainingMinutes = diffInMinutes % 60;

        // Check if the date is from today
        if (createdAt.isToday()) {
            if (diffInMinutes < 60) {
                return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`; // Show minutes if less than 1 hour
            }
            if (diffInHours >= 1) {
                // Show "x hours y minutes ago" if more than 1 hour
                return `${diffInHours} hour${diffInHours === 1 ? '' : 's'}${remainingMinutes > 0 ? ` ${remainingMinutes} minute${remainingMinutes === 1 ? '' : 's'}` : ''} ago`;
            }
        }

        // If it's not from today, show the date in "hh:mm A, DD MMM" format
        return createdAt.format('hh:mm A, DD MMM'); // e.g., "09:15 AM, 13 Jun"
    };

    const subjectFormatter = (item) => {

        if (item?.type == 'task_assigned') {
            if (item?.task.title) {
                return `Assigned you on ${item.task.title}`;
            }
            return `Assigned you on task`;
        } else if (item?.type === 'manual_timer_request') {
            return `Request for task manual hours addition`;
        } else if (item?.type === 'timer_regularization') {
            return `Request for task timer regularization`
        } else if (item?.type === 'timer_request_accepted') {
            return `Your Timer request accepted`;
        } else if (item?.type === 'timer_request_rejected') {
            return `Your Timer request rejected`;
        } else if (item?.type === 'added_in_project') {
            return `You have been added to project`;
        } else if (item?.type === 'removed_from_project') {
            return `You have been removed from project`;
        } else if (item?.type === 'comment_mention') {
            return `Mentioned you in a comment`;
        } else {
            return 'Mention you';
        }
    }

    const updateNotification = async (id) => {
        try {
            setLoading(true);
            const res = await patchRequest(
                `${UPDATE_NOTIFICATION_FLAG}?notification_id=${id} `,
                {},
                navigate
            );
            if (res.type === 1) {
                return res.type;
            } else {
                errorNotification(res.errormessage);
                return 2;
            }
            setLoading(false);
        }
        catch (error) {
            errorNotification(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }

    }

    const handleRedirect = async (item) => {
        const id = item?.id;

        const res = await updateNotification(id);
        if (res === 1 && inboxCount > 0) {
            const newCount = inboxCount - 1;
            setInboxCount(newCount);
            socket.emit('notificationCount', newCount); // Emit updated count to the server
        }

        if (item?.type === 'task_assigned' && res === 1) {
            if (item?.task?.task_type === 'Project') {
                navigate(`/project-list/project-details/task-details/${item?.task?.id}?project_id=${item?.task?.project_id}`);
            } else {
                navigate(`/my-task/task-details/${item?.task?.id}`);
            }
        } else if (item?.type === 'manual_timer_request' && res === 1) {
            if (item?.task?.task_type === 'Project') {
                secureLocalStorage.setItem("activeTab", "task-request");
                navigate(`/project-list/project-details/${item?.task?.project_id}`)
            } else if (item?.task?.task_type === 'Intern' || item?.task?.task_type === 'Manual') {
                setMyTeamActiveRoute('task-request');
                navigate(`/my-team/my-team-details/${item?.sender?.id}`);
            }
        } else if (item?.type === 'timer_regularization' && res === 1) {
            if (item?.task?.task_type === 'Project') {
                secureLocalStorage.setItem("activeTab", "task-request");
                navigate(`/project-list/project-details/${item?.task?.project_id}`)
            } else if (item?.task?.task_type === 'Intern' || item?.task?.task_type === 'Manual') {
                setMyTeamActiveRoute('task-request');
                navigate(`/my-team/my-team-details/${item?.sender?.id}`);
            }
        } else if (item?.type === 'timer_request_accepted' || item?.type === 'timer_request_rejected') {
            setMyTaskActiveRoute("Requested");
            navigate(`/my-task`);
        } else if (item?.type === 'added_in_project') {
            secureLocalStorage.setItem("activeTab", "dashboard");
            navigate(`/project-list/project-details/${item?.project_id}`)
        } else if (item?.type === 'removed_from_project') {
            fetchActionData();
        } else if (item?.type === 'comment_mention') {
            if (item?.task?.task_type === 'Project') {
                navigate(`/project-list/project-details/task-details/${item?.task.id}?project_id=${item?.task.project_id}`);
            } else if (item?.task?.task_type === 'Project Lead') {
                navigate(`/project-lead/project-lead-details/${item?.task?.id}`);
            } else if (item?.task?.task_type === 'Manual') {
                if (userData.id === item.task?.task_owner_id) {
                    navigate(`/my-task/task-details/${item?.task?.id}`);
                } else {
                    navigate(`/my-team/my-team-task-details/${item?.task?.id}?employee_id=${item?.sender.id}`);
                }
            } else if (item?.task?.task_type === 'Intern') {
                if (userData.id === item.task?.intern_id) {
                    navigate(`/my-task/task-details/${item?.task?.id}`);
                } else {
                    navigate(`/my-team/my-team-task-details/${item?.task?.id}?employee_id=${item?.sender.id}`);
                }
            }
        }
        else {
            fetchActionData();
        }
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <div className='h-[calc(100vh_-_240px)] p-2 overflow-y-auto'>
            {actionData?.length === 0 ? (
                <div className="no__transcation">
                    <img src={noDataFound} alt={noDataFound} loading="lazy" />
                </div>
            ) : (
                <div className='h-full'>
                    <table className='table w-full '>
                        <thead>
                            <tr className=''>
                                <th className='!border-b-[#EFEFEF] font-medium '>From</th>
                                <th className='!border-b-[#EFEFEF] font-medium '>Subject</th>
                                <th className='!border-b-[#EFEFEF] font-medium '>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                actionData.map((item, index) => (
                                    <tr key={index} className='hover:bg-[#f7f7f7] hover:rounded-lg overflow-hidden cursor-pointer'
                                        onClick={(e) => {
                                            if (window.getSelection().toString() === '') {
                                                handleRedirect(item);
                                            }
                                        }}
                                    >
                                        <td className='!border-b-[#EFEFEF] align-middle'>
                                            <div className='flex justify-start items-center gap-2'>
                                                <Avatar className='h-8 w-8 border border-1' src={item?.sender?.profile_image} />
                                                <span>{(item?.sender?.first_name + ' ' + item?.sender?.last_name) || '--'}</span>
                                            </div>
                                        </td>
                                        <td className='!border-b-[#EFEFEF] align-middle'>{item?.type ? subjectFormatter(item) : '--'}</td>
                                        <td className='!border-b-[#EFEFEF] align-middle'>{item?.createdAt ? formatDate(item?.createdAt) : '--'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>

    )
}

export default InboxAction