import React, { useState } from 'react'
import { useStateManager } from 'react-select'
import InboxAction from './InboxAction';
import InboxArchive from './InboxArchive';

const Inbox = () => {

    const [activeTab, setActiveTab] = useState("take_action")

    const handleTabChange = (e) => {
        setActiveTab(e.target.name);

        //Comment add
        //Task add
        //Project member add
        //request for task time

    };

    return (
        <div className='h-full rounded-lg p-4 bg-[#fff]'>
            <ul className="nav nav-pills mb-3 mt-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === "take_action" ? "active" : ""}`}
                        id="pills-take_action-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-take_action"
                        type="button"
                        role="tab"
                        aria-controls="pills-take_action"
                        aria-selected="true"
                        onClick={(e) => handleTabChange(e)}
                        name="take_action"
                    >
                        Take Action
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === "archive" ? "active" : ""}`}
                        id="pills-archive-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-archive"
                        type="button"
                        role="tab"
                        aria-controls="pills-archive"
                        aria-selected="true"
                        onClick={(e) => handleTabChange(e)}
                        name="archive"
                    >
                        Archive
                    </button>
                </li>
            </ul>
            <div className="program_scroll">
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className={`tab-pane fade ${activeTab === "take_action" ? "show active" : ""
                            }`}
                        id="pills-take_action"
                        role="tabpanel"
                        aria-labelledby="pills-take_action-tab"
                    >
                        <div>
                            {activeTab === "take_action" && <InboxAction />}
                        </div>
                    </div>
                    <div
                        className={`tab-pane fade ${activeTab === "archive" ? "show active" : ""
                            }`}
                        id="pills-archive"
                        role="tabpanel"
                        aria-labelledby="pills-archive-tab"
                    >
                        <div>
                            {activeTab === "archive" && <InboxArchive />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inbox