import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import noDataFound from "../../components/assets/No data-rafiki 1.svg";
import Modal from "../../views/admin/Modal/Modal";
import { getRequest } from "../axiosClient";
import CustomPagination from "../common/CustomPagination";
import LoadingSpinner from "../loader/LoadingSpinner";
import { errorNotification } from "../toast-notification/common-toast";
import clock_inActive from '../../components/assets/clock_inActive.svg'
import { GET_EMPLOYEE_TASK, GET_EMPLOYEE_TOTAL_HOURS } from "../utils";
import dayjs from "dayjs";

export default function DayWiseReport() {
  // const getYesterday = () => {
  //   var today = new Date();
  //   var yesterday = new Date(today);
  //   yesterday.setDate(today.getDate() - 1);
  //   const day = dayjs().add(-1, "day");
  //   console.log("ahsdjsfj====>",day.format("DD-MM-YYYY"));
  //   const monthNames = [
  //     "01",
  //     "02",
  //     "03",
  //     "04",
  //     "05",
  //     "06",
  //     "07",
  //     "08",
  //     "09",
  //     "10",
  //     "11",
  //     "12",
  //   ];
  //   var month = today.getMonth();
  //   yesterday =
  //     yesterday.getFullYear() +
  //     "-" +
  //     monthNames[month] +
  //     "-" +
  //     yesterday.getDate();

  //   return yesterday;
  // };

  const navigate = useNavigate();
  const yesterday = dayjs().add(-1, "day").format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);
  const [listshow, setlistShow] = useState(false);
  const [employeeDataList, setEmployeeDataList] = useState([]);
  const [employeeDataTaskList, setEmployeeDataTaskList] = useState([]);
  const [emplopyeeId, setEmplopyeeId] = useState(null);
  const [filterDate, setFilterDate] = useState(yesterday);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const isMounted = useRef(false);
  const [pageInformation, setPageInformation] = useState({});

  const getEmployeeReportList = async () => {
    try {
      setLoading(true);
      const res = await getRequest(
        `${GET_EMPLOYEE_TOTAL_HOURS}?date=${filterDate}&page=${page}&limit=${pageSize}`,
        null,
        navigate
      );
      const list = res.data.data.page_data.map((item) => {
        let name = item.first_name + " " + item.last_name;
        let email = item.email;
        let totalDuration = item.total_duration_second;
        return {
          id: item.id,
          label: name,
          duration: totalDuration,
          email: email,
        };
      });
      setEmployeeDataList(list);
      setPageInformation(res.data.data.page_information);
      setLoading(false);
    } catch (error) {
      errorNotification(error);
      setLoading(false);
    }
  };
  const getEmployeeReportData = async () => {
    try {
      if (!emplopyeeId?.id) {
        return
      }
      const res = await getRequest(
        `${GET_EMPLOYEE_TASK}?date=${filterDate}&user_id=${emplopyeeId?.id}`,
        null,
        navigate
      );
      const tasklist = res.data.data.map((item) => {
        let taskName = item?.task?.title;
        let projectName = item?.task?.project?.name;
        let totalDuration = item?.duration_second;
        return {
          task: taskName,
          project: projectName,
          duration: totalDuration,
        };
      });
      setEmployeeDataTaskList(tasklist);
    } catch (error) {
      errorNotification(error);
    }
  };

  useEffect(() => {
    getEmployeeReportList();
  }, [filterDate, page]);

  useEffect(() => {
    getEmployeeReportData();
  }, [emplopyeeId]);

  const handleView = (item) => {
    setlistShow(true);
    setEmplopyeeId(item);
    getEmployeeReportData();
  };
  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="project__section bg-[#fff] rounded-[12px] p-[10px]">
      <div className="w-full project__subsection">
        <div className="w-full flex flex-row justify-end items-center">
          <div className="w-full flex justify-end items-center px-[14px] mb-2">
            <input
              type="date"
              placeholder="Select a date"
              name="filter"
              className="h-10 min-w-44 w-rounded-lg border rounded-lg p-2"
              value={filterDate}
              onChange={(e) => {
                setFilterDate(e.target.value);
                setPage(1);
                isMounted.current = false;
                setPageInformation({});
              }}
            />
          </div>
        </div>
      </div>
      {employeeDataList?.length > 0 ? (
        <div className="project__listing">
          <div className="project__data pb-4">
            <div className="d-flex flex-column flex-md-row align-items-start justify-content-between min-h-[600px] project__data_table ">
              <table className="table project__list__table table-responsive">
                <thead className="report__header__border">
                  <tr className="border-style">
                    <th scope="col">
                      Project
                    </th>
                    <th scope="col">
                      task
                    </th>
                    <th scope="col" >
                      Duration
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {employeeDataList.map((emp) => (
                    <tr key={emp?.id}>
                      <td className="table_tr_style align-middle font-normal">{emp?.id}</td>
                      <td className="table_tr_style align-middle font-normal">
                        {emp?.label}
                      </td>
                      <td className="table_tr_style align-middle font-normal">
                        {dayjs.duration(emp?.duration, "seconds").format("HH:mm")}
                      </td>
                      <td className="text-center align-middle !border-b-0 d-flex justify-content-end">
                        <button
                          className="bg-[#f47e53] text-[#fff] w-[fit-content] font-normal py-[8px] px-[18px] rounded-[6px] text-[16px] cursor-pointer  ms-2"
                          onClick={() => handleView(emp)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <CustomPagination
              page={page}
              pageInformation={pageInformation}
              setPage={setPage}
              totalPages={pageInformation?.last_page}
            />
          </div>
        </div>
      ) : (
        <div className="no__transcation">
          <img src={noDataFound} alt={noDataFound} loading="lazy" />
        </div>
      )}
      <Modal
        className="project-modal modal-heading"
        key="dayWiseData"
        title={"Employee Work data"}
        onClose={() => {
          setlistShow(false);
        }}
        show={listshow}
      >
        <div className="modal-body add__project__form">
          {employeeDataTaskList?.length > 0
            ? (
              <div>
                <table className="table project__list__table table-responsive">
                  <thead className="report__header__border">
                    <tr className="border-style">
                      <th scope="col">
                        Task
                      </th>
                      <th scope="col">
                        Project
                      </th>
                      <th scope="col" >
                        <div className="flex flex-row justify-start items-center gap-2">
                          <img src={clock_inActive} /> {dayjs.duration(emplopyeeId?.duration, "seconds").format("HH:mm")}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>


                    {employeeDataTaskList?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="table_tr_style align-middle font-normal"> {item.task}  </td>
                          <td className="table_tr_style align-middle font-normal"> {item.project || "--"}  </td>
                          <td className="table_tr_style align-middle font-normal">
                            <div className="flex flex-row justify-start items-center gap-2">
                              <img src={clock_inActive} />  {dayjs.duration(item.duration, "seconds").format("HH:mm")}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                No data found
              </div>
            )}
        </div>
        <Row className="add__close__section mt-3">
          <Col xs={12}>
            <button
              type="button"
              className="project__save__btn "
              onClick={() => setlistShow(false)}
            >
              Close
            </button>
          </Col>
        </Row>
      </Modal>
      <style>
        {`
          .modal-body{
            overflow-y: auto;
          }
        `}
      </style>
    </div>
  );
}
