export const LOGIN = "/";
export const SIGNUP = "/signup";
export const FORGETPASSWORD = "/forget-password"
export const RESETPASSWORD = "/reset-password"
export const VERIFYEMAIL = "/verify-email"
export const AUDITOR_INVITATION_ACCEPT = "/auditor-accept-invitation"

export const ADMIN_DASHBOARD = "dashboard";
export const ADMIN_SCANS = "admin-scans";
export const ADMIN_REPORT = "admin-report";
export const ADMIN_PROGRAMS = "ad-program";
export const ADMIN_CHAT = "admin-chat";
export const ADMIN_SETTINGS = "admin-settings";
export const ADMIN_AUDIT = "admin-audit";
export const ADMIN_AUDIT_DETAILS = "admin-audit/auditdetails/:id";
export const ADMIN_LIST_EDIT = "edit-list";
export const ADMIN_VIEW_AUDIT_VULNERABILITIES_DETAILS = "admin-audit/auditdetails/:id/admin-vulnerabilities-details/:id"

export const COMPANY_DASHBOARD = "company-dashboard";
export const COMPANY_REPORTS = "company-reports";
export const COMPANY_CHAT_EDIT = "company-chatEdit";
export const COMPANY_SCANS = "company-scans";
export const COMPANY_SCANS_FORM = "company-scans/company-scan-form/:id";
export const COMPANY_INTEGRATION = "company-integration";
export const COMPANY_SETTINGS = "company-settings";
export const COMPANY_AUDIT = "company-audit";
export const COMPANU_BOUNTY_REQUEST = "company-bounty-request"
export const COMPANY_AUDIT_CHAT_EDIT = "company-audit-chatEdit";


export const DASHBOARD = "dashboard";
export const SETTING = "setting";
export const CONNECT = "connect";
export const PAYMENT = "payment";
export const PROGRAM = "program";
export const PROGRAM_DETAILS = "program/:name";
export const SUBMIT_REPORT = "program/:name/submit-report";
export const LEADER_BOARD = "leader-board";
export const REPORT = "reports";
export const AUDIT_SETTING = "audit-setting"

export const ADD_PROGRAM = "add-programs";
export const CONTACT_US = "contact-us";
export const JOIN_US = "join-us";
export const ADMIN_PROGRAM_DETAILS = "/ad-program/ad-details/:id";
export const EDIT_PROGRAM = "/ad-program/ad-details/:id/edit-program";

export const VIEW_REPORT_ADMIN = "/report/view-report/:id";
export const VIEW_REPORT_HUNTER = "/reports/view-hunter-report/:id";
export const EMPLOYEE_LISTING = "employee-list";
export const VIEW_REPORT_COMPANY = "/company-reports/view-report-company/:id";
export const COMPANY_PROGRAM_EDIT = "company-dashboard/company-program/:id";
export const VULNERABILITIES = "add-vulnerabilities";
export const AUDIT_SUBMIT_VULNERABILITIES = "audit-setting/submit-audit-vulnerabilities"
export const AUDIT_SUBMIT_VULNERABILITIES_GETDRAFT = "audit-setting/submit-audit-vulnerabilities/:id"
export const BOUNTY_PROGRAM_REQUEST = "bounty-program-request"

export const CLIENT_LIST = "client-list"
export const DAILY_UPDATES = "time-sheet"
export const PROJECT_LIST = "project-list"
export const PROJECT_DETAILS = "project-list/project-details/:id"
export const PROJECT_TASK_DETAILS = "project-list/project-details/task-details/:id"
export const PROJECT_TASK_REQUESTS = "project-list/project-details/task-requests/:id"
export const PROJECT_LEAD = "project-lead";
export const CANDIDATE_LEAD = "candidate-leads";
export const ASSETS = "assets";
export const MY_TEAM = "my-team";
export const INBOX = "inbox";
export const CANDIDATE_LEADS_DETAILS = "candidate-leads/client-details/:id"
export const PROJECT_LEAD_DETAILS = "project-lead/project-lead-details/:id";
export const EMPLOYEE_DETAILS = "employee-list/employee-details/:id";
export const EDIT_EMPLOYEE_DETAILS = "employee-list/employee-details/edit-employee/:id";
export const ASSETS_DETAILS = "assets/assets-details/:id";
export const ORGANIZATION = "organization";
export const MY_TEAM_DETAILS = "my-team/my-team-details/:id";
export const MY_TEAM_TASK_DETAILS = "my-team/my-team-task-details/:id";
export const EDIT_ASSETS_DETAILS = "assets/assets-details/edit-assets/:id";
export const MY_TASK = "my-task";
export const TASK_DETAILS = "my-task/task-details/:id";

export const VIEW_AUDIT_VULNERABILITIES_HUNTER = "/audit-setting/view-auditor-vulnerabilities/:id";
export const UPDATE_AUDIT_VULNERABILITIES_SENIOR_AUDITOR = "/audit-setting/update-auditor-vulnerabilities/:id";
export const VIEW_AUDIT_VULNERABILITIES_COMPANY = "/company-audit/view-vulnerabilities-company/:id";

export const CREATE_BLOG_ADMIN = "/create-blog"
export const BLOG_LIST_ADMIN = "blog-list"

export const NOT_FOUND = "/404";


